<template>
    <div>
        <div class="order-card">
            <div class="header-title">
                <div class="blue"></div>
                <div class="title">用户统计</div>
            </div>
            <div class="pay-main">
                <div class="pay-main-item one">
                    <div class="tit">充值数据统计</div>
                    <div class="pay-main-flx">
                        <div class="item w25">
                            <img src="@/assets/images/user/u1.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    剩余钻石(钻)
                                </div>
                                <div class="number">
                                    {{ statInfo.diamond || 0 }}
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="item w25">
                            <img src="@/assets/images/user/u1.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    累计钻石充值(钻)
                                </div>
                                <div class="number">
                                    {{ statInfo.total_diamond || 0 }}
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="item w25">
                            <img src="@/assets/images/user/u5.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    累计时长充值(分)
                                </div>
                                <div class="number">
                                    {{ statInfo.total_video_duration || 0 }}
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="item w25">
                            <img src="@/assets/images/user/u6.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    训练次数充值(次)
                                </div>
                                <div class="number">
                                    {{ statInfo.total_advanced_cust || 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pay-main-item two">
                    <div class="tit">剩余统计</div>
                    <div class="pay-main-flx">
                        <div class="item w25">
                            <img src="@/assets/images/user/u1.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    钻石余额
                                </div>
                                <div class="number">
                                    0
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="item w25">
                            <img src="@/assets/images/user/u2.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    视频时长余额
                                </div>
                                <div class="number">
                                    0
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="item w25">
                            <img src="@/assets/images/user/u3.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    训练次数充值(次)
                                </div>
                                <div class="number">
                                    0
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="item w25">
                            <img src="@/assets/images/user/u4.png" alt="">
                            <div class="pay-info">
                                <div class="name">
                                    音频时长余额
                                </div>
                                <div class="number">
                                    0
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {  },
    props: {
        statInfo: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style scoped lang="scss">
.order-card {
    width: 100%;
    padding: 20px;
    background: #E0EBFF;
    border-radius: 10px;
    margin-top: 20px;
    .header-title {
        display: flex;
        align-items: center;
        .blue {
            width: 5px;
            height: 18px;
            background: $blueColor1;
            border-radius: 3px;
        }
        .title {
            padding-left: 10px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
    .pay-main {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .pay-main-item {
            height: 136px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 20px 0;
            flex: 1;
            .tit {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                padding-left: 20px;
            }
            .pay-main-flx {
                display: flex;
                align-items: center;
                margin-top: 28px;
                .item {
                    height: 48px;
                    padding-left: 30px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 40px;
                        height: 40px;
                    }
                    .pay-info {
                        padding-left: 20px;
                        .name {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 17px;
                        }
                        .number {
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 33px;
                            padding-top: 5px;
                        }
                        .red {
                            color: #ff5f5f;
                        }
                    }
                }
                .line {
                    width: 1px;
                    height: 48px;
                    background: #d9d9d9;
                }
                .w25 {
                    width: 25%;
                }
                .w50 {
                    width: 50%;
                }
            }
        }
        .pay-main-item.one {
            // width: 50%;
        }
        .pay-main-item.two {
            // width: 50%;
            margin-left: 20px;
        }
    }
}
</style>
