<template>
	<div class='user_list'>

		<div class="vue_title_txt">
			<span class="title">代理列表</span>
			<span class="line">/</span>
			<span class="tip">代理详情</span>
		</div>
		<div class="p20">
			<search-params-one ref="SearchParamsOne" @search="searchStat"></search-params-one>
			<tongji-card :statInfo="statInfo"></tongji-card>
		</div>

		<el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="充值记录" name="tab1"></el-tab-pane>
			<el-tab-pane label="钻石消耗" name="tab2"></el-tab-pane>
			<el-tab-pane label="兑换码兑换" name="tab3"></el-tab-pane>
		</el-tabs>
		<div class="main" v-if="activeName == 'tab1'">
			<search-params ref="SearchParams" :is_order_num="true" :is_corp_name="true" :is_corp_phone="true"
				:is_recharge_source="true" :recharge_source_list="recharge_source_list" :is_busy_time="true"
				@search="search" @resetFrom="resetFrom"></search-params>

			<table-list :tableData="list" :tableOption.sync="tableOption" @cell-click="cellClick">
		
			</table-list>
			<div class="page_box">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
		<div class="main" v-if="activeName == 'tab2'">
			<search-params ref="SearchParams" :is_order_num="true" :is_corp_name="true" :is_corp_phone="true"
				:is_category="true" :category_list="category_list" :is_order_source="true"
				:order_source_list="order_source_list" :is_busy_time="true" @search="search"
				@resetFrom="resetFrom"></search-params>
			<table-list :tableData="list" :tableOption.sync="tableOption2" @cell-click="cellClick">
	
			</table-list>
			<div class="page_box">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
		<div class="main" v-if="activeName == 'tab3'">
			<search-params ref="SearchParams" :is_corp_name="true" :is_corp_phone="true" @search="search"
				@resetFrom="resetFrom"></search-params>
			<table-list :tableData="list" :tableOption.sync="tableOption3" @cell-click="cellClick">
		
			</table-list>
			<div class="page_box">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
	</div>
</template>

<script>
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import SearchParamsOne from '../components/search/detail_search1.vue' // 筛选项
import TableList from '../components/tableList/index.vue' // 列表
import TongjiCard from './tongjiCard.vue' // 统计
import SearchParams from '../components/search/index.vue' // 筛选项

export default {
	components: {
		PagingPage,
		SearchParamsOne,
		TableList,
		TongjiCard,
		SearchParams,
	},
	props: {},
	data() {
		return {
			activeName: 'tab1',
			params: {},
			recharge_source_list: [],
			category_list: [],
			order_source_list: [],
			list: [],
			tableOption: [
				{ label: '订单号', prop: 'order_num' },
				{ label: '充值来源', prop: 'order_source_text' },
				{ label: '名称', prop: 'order_name' },
				{ label: '登录手机号', prop: 'mobile' },
				{ label: '折扣', prop: 'discount' },
				{ label: '充值额度', prop: 'rechargeDetail', solt: true },
				{ label: '合同金额(元)', prop: 'total_amount' },
				{ label: '操作账户', prop: 'operator_name', },
				{ label: '交易时间', prop: 'create_time' },
			],
			tableOption2: [
				{ label: '订单号', prop: 'order_num' },
				{ label: '订单来源', prop: 'order_source_text' },
				{ label: '企业名称', prop: 'nickname' },
				{ label: '企业手机号', prop: 'mobile' },
				{ label: '商品类别', prop: 'product_category_text' },
				{ label: '商品名称', prop: 'product_name' },
				{ label: '消耗额度', prop: 'total_amount' },
				{ label: '交易时间', prop: 'create_time' },
			],
			tableOption3: [
				{ label: '订单号', prop: 'order_num' },
				{ label: '订单来源', prop: 'order_source_text' },
				{ label: '企业名称', prop: 'nickname' },
				{ label: '企业手机号', prop: 'data4' },
				{ label: '商品类别', prop: 'product_category_text' },
				{ label: '商品名称', prop: 'product_name' },
				{ label: '交易时间', prop: 'create_time' },
			],
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			id: '',
			statInfo: {}, // 统计信息
			paramsStat: {}
		}
	},
	computed: {},
	watch: {},
	created() {
		this.id = this.$route.query.id
		this.getStat()
		this.getParams()
		this.getList()
	},
	mounted() { },
	methods: {
		searchStat(params) {
			this.paramsStat.type = params.timeType
			this.paramsStat.time_period = params.date ? `${params.date[0]}|${params.date[1]}` : ''
			this.getStat()
		},

		// 统计信息
		getStat() {
			const data = {
				id: this.id,
				...this.paramsStat,
			}
			this.$userApi.agencyStat(data).then(res => {
				if (res.code == 1000) {
					this.statInfo = res.result
				} else {
					this.$errMsg(res.message)
				}
			})
		},
		handleClick(tab) {
			this.activeName = tab.name
			this.resetFrom()
		},
		getParams() {
			// 充值订单列表筛选
			this.$userApi.getRechargeParams().then(res => {
				if (res.code == 1000) {
					this.recharge_source_list = res.result.order_source
				}
			})
			// 消耗订单列表筛选
			this.$userApi.getConsumeParams().then(res => {
				if (res.code == 1000) {
					this.order_source_list = res.result.order_source
					this.category_list = res.result.product_category
				}
			})
		},
		search(params) {
			if (this.activeName == 'tab1') {
				this.params.start_date = params.busy_time ? params.busy_time[0] : ''
				this.params.end_date = params.busy_time ? params.busy_time[1] : ''
				this.params.nickname = params.corp_name
				this.params.mobile = params.corp_phone
				this.params.agency_mobile = params.agancy_phone
				this.params.order_source = params.recharge_source
				this.params.order_num = params.order_num
			} else if (this.activeName == 'tab2') {
				this.params.start_date = params.busy_time ? params.busy_time[0] : ''
				this.params.end_date = params.busy_time ? params.busy_time[1] : ''
				this.params.nickname = params.corp_name
				this.params.mobile = params.corp_phone
				this.params.category = params.category
				this.params.order_num = params.order_num
				this.params.order_source = params.order_source
			} else if (this.activeName == 'tab3') {
				this.params.order_num = params.order_num
				this.params.mobile = params.corp_phone
			}
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
            this.$refs.SearchParams.resetFrom()
			this.page = 1
			this.list = []
			this.total = 0
			this.params = {}
			this.getList()
		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		getList() {
			const params = {
				member_id: this.id,
				page: this.page,
				limit: this.limit,
				...this.params,
			}
			// 消耗和兑换列表：pay_type必传  3=钻石消耗 4=兑换码
			if (this.activeName == 'tab2') params.pay_type = 3
			if (this.activeName == 'tab3') params.pay_type = 4

			// 充值列表
			if (this.activeName == 'tab1') {
				this.loading = true
				this.$userApi.agencyCustomerOrder(params).then(res => {
					this.loading = false

					if (res.code == 1000) {
						this.list = res.result.data
						this.total = res.result.total
					} else {

					}
				})

			} else { // 消耗列表、兑换码列表
				this.loading = true
				this.$userApi.agencyCustomerConsumeOrder(params).then(res => {
					this.loading = false

					if (res.code == 1000) {
						this.list = res.result.data
						this.total = res.result.total
					} else {

					}
				})

			}
		},
		cellClick(e) { },
	},
}
</script>

<style lang='scss' scoped>
@import "./index.scss";

.user_list {
	min-width: 1680px;

	height: calc(100vh - 270px);

}
</style>